import { Controller } from "@hotwired/stimulus"
import bindDropdown from "@/javascript/shared/bind_dropdown"
import fetch from "@/javascript/components/tools/fetch"

export default class extends Controller {
  connect() {
    if (!this.element.querySelector("#timesheet_rate_id")) return

    if (this.element.querySelector("#timesheet_assignment_id")) {
      bindDropdown("timesheet_assignment_id", [{ id: "timesheet_rate_id", hide_empty: false, auto_one: true }], { id_based: true })
      this.preselectAssignment()
    }

    this.addEventListeners()
  }

  addEventListeners() {
    this.element.querySelector("#start_yesterday").addEventListener("change", this.updateStartYesterday.bind(this))
    this.element.querySelector("#start_today").addEventListener("change", this.updateStartToday.bind(this))
    this.element.querySelector("#end_today").addEventListener("change", this.updateEndToday.bind(this))
    this.element.querySelector("#end_tomorrow").addEventListener("change", this.updateEndTomorrow.bind(this))

    this.element.querySelector("#timesheet_rate_id").addEventListener("change", () => this.calculateTotal())
    this.element.querySelectorAll(".time-select select").forEach(select => {
      select.addEventListener("change", () => {
        this.updateStartTimeZone()
        this.updateEndTimeZone()
        this.updateBreaks()
        this.calculateTotal()
        this.updateTotalTime()
      })
    })
  }

  updateStartYesterday() {
    this.adjustDay("#timesheet_start_time_3i", -1)
    this.updateStartTimeZone()
    this.calculateTotal()
    this.updateTotalTime()
  }

  updateStartToday() {
    this.adjustDay("#timesheet_start_time_3i", 1)
    this.updateStartTimeZone()
    this.calculateTotal()
    this.updateTotalTime()
  }

  updateEndToday() {
    this.adjustDay("#timesheet_end_time_3i", -1)
    this.updateEndTimeZone()
    this.calculateTotal()
    this.updateTotalTime()
  }

  updateEndTomorrow() {
    this.adjustDay("#timesheet_end_time_3i", 1)
    this.updateEndTimeZone()
    this.calculateTotal()
    this.updateTotalTime()
  }

  adjustDay(selector, value) {
    const element = this.element.querySelector(selector)
    element.value = parseInt(element.value) + value
  }

  updateStartTimeZone() {
    this.updateTimeZone("start_time_zone", this.startTime())
  }

  updateEndTimeZone() {
    this.updateTimeZone("end_time_zone", this.endTime())
  }

  updateTimeZone(elementId, time) {
    const url = new URL("/timesheets/api/time_zone", window.location.origin)
    url.searchParams.set("time", time)
    fetch(url)
      .then(response => response.json())
      .then(data => this.element.querySelector(`#${elementId}`).innerHTML = data.time_zone)
  }

  updateTotalTime() {
    const url = new URL("/timesheets/api/total_time", window.location.origin)
    url.searchParams.set("start_time", this.startTime())
    url.searchParams.set("end_time", this.endTime())
    url.searchParams.set("breaks", this.breaks())

    fetch(url)
      .then(response => response.json())
      .then(data => {
        let totalMins = data.time
        let negative = totalMins < 0
        if (negative) totalMins *= -1

        const hours = Math.floor(totalMins / 60)
        let mins = totalMins % 60
        mins = mins < 10 ? `0${mins}` : mins

        this.element.querySelector("#total_time").innerHTML = negative ? `-${hours}:${mins}` : `${hours}:${mins}`
      })
  }

  updateBreaks() {
    const hours = parseInt(this.element.querySelector("#timesheet_breaks_h").value) * 60
    const mins = parseInt(this.element.querySelector("#timesheet_breaks_m").value)
    this.element.querySelector("#timesheet_breaks").value = hours + mins
  }

  calculateTotal() {
    if (!this.rateId()) {
      this.element.querySelector("#total_charge").innerHTML = "-"
      return
    }

    const url = new URL("/timesheets/api/total_charge", window.location.origin)
    url.searchParams.set("start_time", this.startTime())
    url.searchParams.set("end_time", this.endTime())
    url.searchParams.set("breaks", this.breaks())
    url.searchParams.set("rate_id", this.rateId())

    fetch(url)
      .then(response => response.json())
      .then(data => this.element.querySelector("#total_charge").innerHTML = data.total)
  }

  startTime() {
    return this.getTime("timesheet_start_time")
  }

  endTime() {
    return this.getTime("timesheet_end_time")
  }

  breaks() {
    return parseInt(this.element.querySelector("#timesheet_breaks").value)
  }

  rateId() {
    return this.element.querySelector("#timesheet_rate_id").value
  }

  getTime(prefix) {
    const parts = [1, 2, 3].map(i => this.element.querySelector(`#${prefix}_${i}i`).value).join("-")
    const time = [4, 5].map(i => this.element.querySelector(`#${prefix}_${i}i`).value).join(":")
    return `${parts} ${time}`
  }

  preselectAssignment() {
    const assignment = this.element.querySelector("#timesheet_assignment_id")
    if (assignment.options.length === 2 && assignment.value === "") {
      assignment.value = assignment.options[1].value
      assignment.dispatchEvent(new Event("change"))
    }
  }
}
