export default function getSelectize (id) {
  let element;

  if (typeof(id === "string")) {
    if(id.charAt(0) !== '#') {
      id = '#' + id
    }
    element = $(id)
  } else {
    element = id
  }

  return element.selectize()[0].selectize
}
