// Code to bind child dropdowns to parent, based on optgroup filtering
// As demonstrated in this rails cast:
//   http://railscasts.com/episodes/88-dynamic-select-menus-revised
// Parent: Id of parent
// Children: Array of objects of the form
//   id:        Child ID
//   selectize:  If present, these options will be used to selectize the child
//   hide_empty: If true, the select box will be hidden when empty
//   hide_one:   If true, child select boxes with only one choice will be
//               automatically selected and hidden
//   auto_one:   If true, child select boxes with only one choice will be
//               automatically selected
// Options:
//   id_based: If true, specifies that the option group labels are using database IDs
//             not names

import getSelectize from "@/javascript/shared/selectize_helpers";
import $ from "jquery"

const boundDropDown = {}

export default function bindDropdown (parent, children, options = {}) {
  // Create an object in which to store our data

  let data = boundDropDown[parent] ||= {}
  data.children = children
  data.options = options

  // Store each child's original dropdown data
  $.each(data.children, (index, child) => {
    child['options'] ||= $(`#${child['id']}`).html()
  })

  // Filter the child options based on the parent. This is a function that's
  // called at startup and when the parent changes
  data.filter_options = function(parent) {
    const data = boundDropDown[parent]

    let selected
    if(data.options.id_based) {
      selected = $(`#${parent} :selected`).val() || "" // val() can return undefined
    } else {
      selected = $(`#${parent} :selected`).text()
    }
    const escaped_selected = selected.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1')

    $.each(data.children, function(index, child) {
      const child_element = $(`#${child['id']}`)
      // Filter the new options from the original data
      const child_options = $(child['options']).filter(`optgroup[label='${escaped_selected}']`)
      // If it's a selectized control then it needs to be turned off while we
      // change the options
      // The first time this is run on startup a child will not yet had been
      // selectized, so we check the class
      if(child_element.hasClass('selectized')) {
        getSelectize(child_element).destroy()
      }

      // Add the options
      if(child_options.html()) {
        child_options.prepend($('<option>', {value: ""}).text("Please select"))
        child_element.html(child_options.html())
      } else {
        child_element.empty()
      }

      // Selectize the child if neccessary
      if(child['selectize']) {
        child_element.selectize(child['selectize'])
      }
    })
  }

  // Bind a change event to the parent to update the children
  $(`#${parent}`).change(function() {
    const id = $(this).attr('id')
    const data = boundDropDown[id]
    data.filter_options(id)

    // Reset the values of the child dropdowns now the parent value has changed
    $.each(data.children, function(index, child) {
      const child_element = $(`#${child['id']}`)

      let num_options
      if(child_element.hasClass('selectized')) {
        // Get the number of selectize options in this ridiculously convoluted way

        num_options = Object.keys(getSelectize(child_element).options).length
      } else {
        num_options = child_element[0]['options'].length
      }

      if((child['hide_one'] || child['auto_one']) && num_options === 2) {
        // Only two elements (including the please select option, which means we auto
        // select the second option and then hide it

        // This strange code gets the value of the second option in the child_element
        // select box
        const new_value = $(child_element[0]['options'][1])[0].value
        child_element.val(new_value).change()

        if(child['hide_one']) {
          child_element.parent().hide()
        }
      } else {
        // Call hide/show on the parent because we want the bootstrap formgroup
        if(child['hide_empty'] && num_options === 0) {
          child_element.parent().hide()
        } else {
          child_element.parent().show()
        }

        child_element.val('').change()
        // Selectize values are cleared in a different way
        if(child_element.hasClass('selectized')) {
          getSelectize(child_element).clear()
        }
      }
    })
  })

  // Startup code - the child dropdowns need to be filtered on startup
  data.filter_options(parent)
}
