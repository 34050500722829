import { Controller } from "@hotwired/stimulus"
import reportError from "@/javascript/components/errors"

/* Connects to data-controller="turbo-modal"
* The title of the modal can be set from content within the modal by adding a
* an element with data-turbo-modal-target="modalTitle" and the title text as the
* innerHTML. The element will be removed from the modal and the innerHTML will be
* set as the title of the modal.
*/

export default class extends Controller {
  static targets = ['modalTitle']

  connect() {
    this.element.classList.add('open')
    const body = this.element.closest('body')
    body.classList.add('with-modal')
    if (this.hasModalTitleTarget) {
      this.element.querySelector('#turbo_modal_title').innerHTML = this.modalTitleTarget.innerHTML
      this.modalTitleTarget.remove()
    }
  }
  disconnect() {
    const body = document.querySelector('body')
    body.classList.remove('with-modal')
    if (!document.querySelector('.modal-empty')) {
      return
    }
    if (document.querySelector('.modal-empty').checkVisibility()) {
      // NB if this error is logged it's because an independent modal
      // was "closed" with either the hideModal method or a turbo_stream
      // without navigating away from the page. Most likely a controller action
      // that has not used a turbo stream redirect.
      reportError({
          message: 'Modal closed without navigating away from the page',
          source: 'turbo_modal_controller.js',
          stack: null
        })
    }
  }
  hideModal(e) {
    if (e.cancelable) {
      e.preventDefault()
    }
    if (!this.element.closest("turbo-frame#modal") && e.target.href) {
      window.location.href = e.target.href
      return
    }
    this.element.parentElement.removeAttribute("src")
    this.element.remove()
  }
}
